import React from 'react'

import useSiteMeta from '@babelcoder/gatsby-theme-base/src/hooks/useSiteMeta'
import Jumbotron from '@babelcoder/gatsby-theme-base/src/components/Jumbotron'
import HomeHeaderImage from '@assets/images/home-header.svg'

function Header() {
  const { siteSlogan, siteSloganDesc } = useSiteMeta()
  return (
    <Jumbotron title={siteSlogan} Image={HomeHeaderImage}>
      {siteSloganDesc}
    </Jumbotron>
  )
}

export default Header
