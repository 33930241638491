import React from 'react'
import styled, { css } from 'styled-components'
import SubscriptionBox from '@babelcoder/gatsby-theme-weekly/src/components/SubscriptionBox'
import Container from './Container'
import Title from './Title'

const Wrapper = styled.section`
  ${({ theme }) => css`
    text-align: center;
    background-color: ${theme.colors.neutral.gray300};
  `}
`

const Desc = styled.p`
  color: ${({ theme }) => theme.colors.neutral.gray900};
`

const StyledSubscriptionBox = styled(SubscriptionBox)`
  justify-content: center;
`

function Subscribe() {
  return (
    <Wrapper>
      <Container>
        <Title>ไม่พลาดทุกการเชื่อมต่อ</Title>
        <Desc>
          เพียงกรอกอีเมล์ไว้กับเรา
          ทุกบทความหรือข่าวสารในวงการการพัฒนาซอฟต์แวร์รวมถึงคอร์สที่น่าสนใจ
          จะถูกส่งตรงถึงมือคุณ เราสัญญาว่าอีเมล์ของคุณจะถูกเก็บเป็นความลับตลอดไป
        </Desc>
        <StyledSubscriptionBox
          centerSubscribedMessage={true}
        ></StyledSubscriptionBox>
      </Container>
    </Wrapper>
  )
}

export default Subscribe
