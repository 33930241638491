import React, { useState, useCallback } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import styled, { css } from 'styled-components';
import { up } from 'styled-breakpoints';

import Modal from '@babelcoder/gatsby-theme-base/src/components/Modal';
import Container from './Container';
import Title from './Title';

const ORGANIZATIONS = [
  'ตลาดหลักทรัพย์แห่งประเทศไทย',
  'บริษัท เอสซีจี ซีเมนต์-ผลิตภัณฑ์ก่อสร้าง จำกัด',
  'TrueMove H Universal Communication Co.,',
  'True Digital And Media Platform Co.,TLD',
  'บริษัท เทรนด์ วีจี 3 จำกัด (สำนักงานใหญ่)',
  'บริษัท ทริปเปิล วี บรอดคาสท์ จำกัด',
  'มหาวิทยาลัยนานาชาติแสตมฟอร์ด',
  'CDG Systems Ltd.',
  'Tri petch IT Solutions Co.,LTD',
  'บริษัท ประกันภัยไทยวิวัฒน์ จำกัด (มหาชน) (สำนักงานใหญ่)',
  'บริษัท โรงพยาบาลกล้วยน้ำไท จำกัด',
  'บริษัท ซี.เจ. เอ็กซ์เพรส กรุ๊ป จำกัด',
  'บริษัท ฟรีวิลล์ เอฟเอ็กซ์ จำกัด',
  'บริษัท ทีบีเอสพี จำกัด (มหาชน)',
  'บริษัท เดต้าว้าว จำกัด',
  'บริษัท อัลทิเมท ซอฟต์แวร์ จำกัด',
  'บริษัท เอเมทเวิร์คส์ จำกัด (สำนักงานใหญ่)',
  'บริษัท มายบิส จำกัด (สำนักงานใหญ่)',
  'บริษัท ซอฟต์นิกซ์ เทคโนโลยี',
  'Soilfish Co.Ltd',
  'Dot Socket Co.,Ltd.',
  'บริษัท โค้ดเด้อทรี จำกัด',
  'บริษัท โกลบิช อคาเดเมีย (ไทยแลนด์) จำกัด',
  'บริษัท อีคอมเมิร์ช โซลูชัน จำกัด (สำนักงานใหญ่)',
  'บริษัท อีว่าแลบส์ (ไทยแลนด์) จำกัด',
  'บริษัท ทัชเทคโนโลยี จำกัด',
  'บริษัท นิวซอฟท์เทคโนโลยี คอนซัลเทนท์ จำกัด',
  'บริษัท ไอที แก็ดเจ็ท จำกัด',
  'บริษัท ซิตี้ เดต้า อนาไลติกส์ จำกัด',
  'บริษัท ไอเอสโค้ด จำกัด',
  'บริษัท วันม็อบบี้ จำกัด',
  'บริษัท ทีเอชเอ็น เน็ตเวิร์ค จำกัด',
  'Phoenixict Co.,Ltd',
  'บริษัท มิราเคิล แอดวาน เทคโนโลยี จำกัด',
  'บริษัท ไอ.ซี.ซี. อินเตอร์เนชั่นแนล จำกัด (มหาชน)',
  'บริษัท 45 เอชคิว จำกัด',
  'บริษัท จีดีแอล กรุ๊ป จำกัด',
  'บริษัท ไฮบริด ดิจิตอล จำกัด',
  'บริษัท ไอโคเน็กซ์ จำกัด',
  'บริษัท ดีพลัส อินเตอร์เทรด จำกัด',
  'บริษัท ไทยบริติช ซีเคียวริตี้ พริ้นติ้ง จำกัด (มหาชน)',
  'บริษัท โสมาดา อินฟอร์เมชั่น เทคโนโลยี จำกัด (มหาชน)',
  'P&P Electronics Technology Co.Ltd',
  'Itorama Consulting (Thailand) Limited',
  'บริษัท อะเคเชีย ไอ.ที. เซอร์วิสเซส (ประเทศไทย) จำกัด (สำนักงานใหญ่)',
  'บริษัท ไอเวอร์สัน เทรนนิ่ง เซ็นเตอร์ จำกัด',
  'บริษัท เมพ คอร์ปอเรชั่น จำกัด',
  'บริษัท อี แอนด์ วี จำกัด',
  'บริษัท อรินแคร์ จำกัด',
];

const Wrapper = styled.section`
  ${({ theme }) => css`
    text-align: center;
    background-color: ${theme.colors.accent.primaryLighten30};
  `}
`;

const ImageList = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  row-gap: ${({ theme }) => theme.spacers.normal};
`;

const ImageItem = styled.div`
  width: calc(100% / 2);

  ${up('medium')} {
    width: calc(100% / 3);
  }

  ${up('large')} {
    width: calc(100% / 5);
  }
`;

const Button = styled.button.attrs({ type: 'button ' })`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.normal};
    margin: ${theme.spacers.normal} 0;
    padding: ${theme.spacers.small} ${theme.spacers.normal};
    color: ${theme.colors.neutral.white};
    background-color: ${theme.colors.main.primary};
    border: 0;
    border-radius: 2rem;
    outline: none;
    cursor: pointer;
  `}
`;

const ModalTitle = styled.header`
  ${({ theme }) => css`
    font-family: ${theme.fontFamily.heading};
    font-size: ${theme.fontSizes.medium};
    text-align: center;
    color: ${theme.colors.neutral.grey1500};
  `}
`;

const ModalWrapper = styled.ul`
  overflow-y: scroll;
  height: 100%;

  ${up('small')} {
    min-width: 500px;
    max-height: 300px;

    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 10px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
  }
`;

export default function Organizations() {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const {
    allFile: { images },
  } = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          absolutePath: { regex: "/site/assets/images/organizations/" }
        }
      ) {
        images: edges {
          node {
            childImageSharp {
              fixed(height: 40, quality: 50, webpQuality: 50) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      }
    }
  `);

  const openModal = useCallback(() => setIsModalOpened(true), [
    setIsModalOpened,
  ]);

  const closeModal = useCallback(() => setIsModalOpened(false), [
    setIsModalOpened,
  ]);

  return (
    <>
      <Wrapper>
        <Container>
          <Title>ลูกค้าของเรา</Title>
          <ImageList>
            {images.map(({ node: image }) => {
              const { fixed } = image.childImageSharp;

              return (
                <ImageItem key={fixed.src}>
                  <Img fixed={fixed}></Img>
                </ImageItem>
              );
            })}
          </ImageList>
          <Button onClick={openModal}>ดูทั้งหมด</Button>
        </Container>
      </Wrapper>
      {isModalOpened && (
        <Modal onClose={closeModal}>
          <ModalTitle>รายนามลูกค้าองค์กรของเรา</ModalTitle>
          <ModalWrapper>
            {ORGANIZATIONS.map((organization) => (
              <li key={organization}>{organization}</li>
            ))}
          </ModalWrapper>
        </Modal>
      )}
    </>
  );
}
