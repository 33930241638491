import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { FaCheckCircle } from 'react-icons/fa'

import Container from './Container'
import TipItem from '@babelcoder/gatsby-theme-tips/src/components/TipItem'
import useTipsPath from '@babelcoder/gatsby-theme-tips/src/hooks/useTipsPath'
import SectionHeader from './SectionHeader'
import SectionList from './SectionList'

function Tips() {
  const { basePath } = useTipsPath()
  const {
    tips: { edges: tips },
  } = useStaticQuery(graphql`
    query {
      tips: allMdx(
        filter: { fileAbsolutePath: { regex: "/content/tips/" } }
        limit: 3
        sort: { fields: frontmatter___createdAt, order: DESC }
      ) {
        edges {
          node {
            excerpt
            frontmatter {
              title
              image {
                childImageSharp {
                  fluid(maxWidth: 500, quality: 50, webpQuality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `)

  return (
    <Container>
      <section>
        <SectionHeader
          title="Tips ล่าสุด"
          buttonText="ดูทั้งหมด"
          to={basePath}
          Icon={FaCheckCircle}
        ></SectionHeader>
        <SectionList>
          {tips.map(({ node: tip }) => (
            <TipItem key={tip.fields.slug} tip={tip}></TipItem>
          ))}
        </SectionList>
      </section>
    </Container>
  )
}

export default Tips
