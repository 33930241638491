import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { FaBookOpen } from 'react-icons/fa'

import Container from './Container'
import SectionHeader from './SectionHeader'
import SectionList from './SectionList'
import SectionItem from './SectionItem'
import useArticlesPath from '@babelcoder/gatsby-theme-blog/src/hooks/useArticlesPath'

function Articles() {
  const { basePath, getArticlePath } = useArticlesPath()
  const {
    articles: { edges: articles },
  } = useStaticQuery(graphql`
    query {
      articles: allMdx(
        limit: 6
        filter: {
          fileAbsolutePath: { regex: "/content/articles/" }
          frontmatter: { published: { ne: false } }
        }
        sort: { fields: frontmatter___createdAt, order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              title
              excerpt
              image {
                childImageSharp {
                  fluid(maxWidth: 350, quality: 50, webpQuality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `)

  return (
    <Container>
      <section>
        <SectionHeader
          title="บทความล่าสุด"
          buttonText="ดูทั้งหมด"
          to={basePath}
          Icon={FaBookOpen}
        ></SectionHeader>
        <SectionList>
          {articles.map(({ node: article }) => (
            <SectionItem
              key={article.fields.slug}
              to={getArticlePath(article.fields.slug)}
              image={article.frontmatter.image.childImageSharp.fluid}
              title={article.frontmatter.title}
              excerpt={article.frontmatter.excerpt}
            ></SectionItem>
          ))}
        </SectionList>
      </section>
    </Container>
  )
}

export default Articles
