import React from 'react'
import styled, { css } from 'styled-components'

import Container from './Container'
import Title from './Title'
import Chat from '@assets/images/chat.svg'
import Online from '@assets/images/online.svg'
import Tech from '@assets/images/tech.svg'

const Wrapper = styled.section`
  ${({ theme }) => css`
    background-color: ${theme.colors.neutral.gray200};
  `}
`

const LearnWithUsFeatures = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: ${({ theme }) => theme.spacers.normal};
`

const LearnWithUsFeatureImageWrapper = styled.div`
  ${({ theme }) => css`
    text-align: center;
    margin-bottom: ${({ theme }) => theme.spacers.normal};
    color: ${theme.colors.neutral.gray1200};

    & > svg {
      height: 200px;
    }
  `}
`

const LearnWithUsFeatureTitle = styled.h3`
  ${({ theme }) => css`
    position: relative;
    text-align: center;
    font-size: ${theme.fontSizes.medium};
    color: ${theme.colors.neutral.gray1000};

    &::before {
      position: absolute;
      content: '';
      bottom: 0;
      width: ${theme.fontSizes.large};
      height: 5px;
      border-radius: ${theme.round.large};
      background-color: ${theme.colors.main.primary};
    }
  `}
`

const LearnWithUsFeatureDesc = styled.p`
  color: ${({ theme }) => theme.colors.neutral.gray900};
`

function LearnWithUsFeature({ Image, title, desc }) {
  return (
    <article>
      <LearnWithUsFeatureImageWrapper>
        <Image></Image>
      </LearnWithUsFeatureImageWrapper>
      <LearnWithUsFeatureTitle>{title}</LearnWithUsFeatureTitle>
      <LearnWithUsFeatureDesc>{desc}</LearnWithUsFeatureDesc>
    </article>
  )
}

function LearnWithUs() {
  return (
    <Wrapper>
      <Container>
        <header>
          <Title>เรียนรู้กับ Babel Coder</Title>
        </header>
        <LearnWithUsFeatures>
          <LearnWithUsFeature
            Image={Tech}
            title="เรียนรู้ได้ทุกที่ทุกเวลา"
            desc="Babel Coder คัดสรรคอร์สคุณภาพและหลากหลายเพื่อคุณ สามารถเข้าถึงเนื้อหาของเราทุกเมื่อที่คุณต้องการเพียงแค่มีอินเตอร์เน็ต"
          ></LearnWithUsFeature>
          <LearnWithUsFeature
            Image={Online}
            title="สอนจากประสบการณ์ตรง"
            desc="การสอนที่ปรับใช้จริงไม่ได้ย่อมไม่เกิดประโยชน์ Babel Coder เน้นการเรียนรู้ที่ใช้ได้จริงและเป็นมาตรฐาน โดยโปรแกรมเมอร์เพื่อโปรแกรมเมอร์"
          ></LearnWithUsFeature>
          <LearnWithUsFeature
            Image={Chat}
            title="สอบถามปัญหาได้ทุกเมื่อ"
            desc="บริการถามตอบจาก Babel Coder ช่วยให้คำปรึกษาในทุกๆหลักสูตร แม้คุณจะไม่ใช่ผู้เรียนของเราก็ตาม"
          ></LearnWithUsFeature>
        </LearnWithUsFeatures>
      </Container>
    </Wrapper>
  )
}

export default LearnWithUs
