import React from 'react';

import SEO from '@babelcoder/gatsby-theme-base/src/components/SEO';
import useSiteMeta from '@babelcoder/gatsby-theme-base/src/hooks/useSiteMeta';
import useCoursesPath from '@babelcoder/gatsby-theme-courses/src/hooks/useCoursesPath';
import useArticlesPath from '@babelcoder/gatsby-theme-blog/src/hooks/useArticlesPath';
import useWeeklyPath from '@babelcoder/gatsby-theme-weekly/src/hooks/useWeeklyPath';
import Header from './Header';
import Articles from './Articles';
import OnlineCourses from './OnlineCourses';
import Organizations from './Organizations';
import CustomerReviews from './CustomerReviews';
import LearnWithUs from './LearnWithUs';
import Subscribe from './Subscribe';
import Tips from './Tips';
import AllWeekly from './AllWeekly';
import ClassroomCourses from './ClassroomCourses';

function Home() {
  const { siteName, siteSlogan, siteSloganDesc, siteUrl } = useSiteMeta();
  const { basePath: coursesPath } = useCoursesPath();
  const { basePath: blogPath } = useArticlesPath();
  const { basePath: weeklyPath } = useWeeklyPath();
  const schema = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    name: siteName,
    description: `${siteSlogan} ${siteSloganDesc}`,
    url: siteUrl,
    sameAs: 'https://facebook.com/babelcoder',
    inLanguage: 'th-TH',
    breadcrumb: {
      '@type': 'BreadcrumbList',
      name: 'ลิงก์หลัก',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          name: 'คอร์สทั้งหมด',
          item: `${siteUrl}${coursesPath}`,
        },
        {
          '@type': 'ListItem',
          position: 1,
          name: 'บทความทั้งหมด',
          item: `${siteUrl}${blogPath}`,
        },
        {
          '@type': 'ListItem',
          position: 1,
          name: 'Babel Coder Weekly',
          item: `${siteUrl}${weeklyPath}`,
        },
        {
          '@type': 'ListItem',
          position: 1,
          name: 'เกี่ยวกับเรา',
          item: `${siteUrl}/about`,
        },
      ],
    },
    mainEntity: [
      {
        '@type': 'Blog',
        name: `บทความทั้งหมดของ ${siteName}`,
        description: `รวมบทความเกี่ยวกับการโปรแกรมภาษาต่าง ๆ และแนวคิดที่เกี่ยวข้องของ ${siteName}`,
        url: `${siteUrl}${blogPath}`,
      },
      {
        '@type': 'WebPage',
        name: `คอร์สทั้งหมดของ ${siteName}`,
        description: `คอร์สสอนการโปรแกรมภาษาต่าง ๆ และแนวคิดที่เกี่ยวข้องของ ${siteName}`,
        url: `${siteUrl}${coursesPath}`,
      },
      {
        '@type': 'WebPage',
        name: `Weekly ทั้งหมดของ ${siteName}`,
        description: `สรุปบทความแนวยาวไปไม่อ่าน (TL;DR) ของ ${siteName}`,
        url: `${siteUrl}${coursesPath}`,
      },
    ],
  };

  return (
    <>
      <SEO
        title={siteSlogan}
        type="article"
        urlPath="/"
        desc={siteSloganDesc}
        schema={schema}
      ></SEO>
      <Header></Header>
      <ClassroomCourses></ClassroomCourses>
      <OnlineCourses></OnlineCourses>
      <Organizations></Organizations>
      <CustomerReviews></CustomerReviews>
      <LearnWithUs></LearnWithUs>
      <Articles></Articles>
      <Tips></Tips>
      <Subscribe></Subscribe>
      <AllWeekly></AllWeekly>
    </>
  );
}

export default Home;
