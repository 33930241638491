import styled, { css } from 'styled-components'

const Title = styled.h2`
  ${({ theme, color }) => css`
    position: relative;
    text-align: center;
    margin-bottom: ${theme.spacers.large};
    color: ${color ? theme.colors[color] : theme.colors.neutral.gray1200};
    font-size: ${theme.fontSizes.large};

    &::before {
      position: absolute;
      content: '';
      left: 50%;
      transform: translate(-50%);
      bottom: 0;
      width: ${theme.fontSizes.large};
      height: 5px;
      border-radius: ${theme.round.large};
      background-color: ${theme.colors.main.primary};
    }
  `}
`

export default Title
