import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { FaNewspaper } from 'react-icons/fa'
import styled, { css } from 'styled-components'
import { up } from 'styled-breakpoints'

import { HeaderTitle } from '@babelcoder/gatsby-theme-weekly/src/components/WeeklyItem'
import useWeeklyPath from '@babelcoder/gatsby-theme-weekly/src/hooks/useWeeklyPath'
import Container from './Container'
import SectionHeader from './SectionHeader'

const SectionList = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
  gap: ${({ theme }) => theme.spacers.normal};

  ${up('medium')} {
    grid-template-columns: repeat(2, 1fr);
  }
`

const SectionItem = styled.article`
  ${({ theme }) => css`
    background-color: ${theme.colors.neutral.gray200};
    border-radius: ${theme.round.normal};
    padding: ${theme.spacers.normal};
  `}
`

const SectionListLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.neutral.gray900};
`

const Title = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.normal};
`

export default function AllWeekly() {
  const { basePath, getWeeklyPath } = useWeeklyPath()
  const {
    allWeekly: { edges: allWeekly },
  } = useStaticQuery(graphql`
    query {
      allWeekly: allWeeklyYaml(
        sort: { fields: publishedAt, order: DESC }
        limit: 1
      ) {
        edges {
          node {
            items {
              title
              category
            }
            issue
          }
        }
      }
    }
  `)
  const { items, issue } = allWeekly[0].node

  return (
    <Container>
      <section>
        <SectionHeader
          title="Weekly ล่าสุด"
          buttonText="ดูทั้งหมด"
          to={basePath}
          Icon={FaNewspaper}
        ></SectionHeader>
        <SectionListLink to={getWeeklyPath(issue)}>
          <SectionList>
            {items.map(({ category, title }) => (
              <SectionItem key={title}>
                <HeaderTitle
                  category={category}
                  title={title}
                  titleAs={Title}
                ></HeaderTitle>
              </SectionItem>
            ))}
          </SectionList>
        </SectionListLink>
      </section>
    </Container>
  )
}
