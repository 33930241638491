import React from 'react';
import styled, { css } from 'styled-components';
import Img from 'gatsby-image';
import Rating from 'react-rating';
import { FaStar } from 'react-icons/fa';

import theme from '@babelcoder/gatsby-theme-base/src/theme/base';

const Wrapper = styled.article`
  position: relative;
  text-align: center;
  margin-top: 3rem;
`;

const AvatarWrapper = styled.div`
  position: relative;
  display: inline-block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -3.5rem;
`;

const Avatar = styled(Img)`
  ${({ theme }) => css`
    border-radius: 7rem;
    border: 0.5rem solid ${theme.colors.neutral.gray1500};
  `}
`;

const ReviewBody = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacers.normal};
    padding-top: 4rem;
    height: 100%;
    background-color: ${theme.colors.neutral.white};
    border-radius: ${theme.spacers.normal};
  `}
`;

const Name = styled.div`
  ${({ theme }) => css`
    text-align: center;
    font-size: ${theme.fontSizes.medium};
    font-weight: bold;
    font-family: ${theme.fontFamily.heading};
  `}
`;

const Line = styled.div`
  ${({ theme }) => css`
    border-top: 2px solid ${theme.colors.neutral.gray300};
    position: relative;
    margin: 0.25rem 0 1rem 50%;
    transform: translateX(-50%);
    width: 50%;

    &::before {
      content: '';
      position: absolute;
      margin-left: -10px;
      bottom: -7px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 10px 0 10px;
      border-color: #fff transparent transparent transparent;
      z-index: 10;
    }

    &::after {
      content: '';
      position: absolute;
      margin-left: -10px;
      bottom: -10px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 10px 0 10px;
      border-color: ${theme.colors.neutral.gray300} transparent transparent
        transparent;
    }
  `}
`;

const Attend = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.small};
    font-style: italic;
    color: ${theme.colors.neutral.gray800};
    padding-bottom: 1rem;
  `}
`;

const Review = styled.blockquote`
  position: relative;
  margin: 2rem 0 3rem 0;
  font-size: 0.85rem;

  &::before,
  &::after {
    font-family: Georgia;
    font-size: 5rem;
    position: absolute;
    opacity: 0.3;
    transform: translateX(-50%);
  }

  &::before {
    content: '“';
    margin: -5rem 0 0 50%;
  }

  &::after {
    content: '”';
    left: 50%;
  }
`;

const CustomerReview = ({ avatar, name, review, attend }) => {
  return (
    <Wrapper>
      <AvatarWrapper>
        <Avatar fixed={avatar.fixed}></Avatar>
      </AvatarWrapper>
      <ReviewBody>
        <Name>{name}</Name>
        <Line></Line>
        <Rating
          readonly
          initialRating={5}
          fullSymbol={
            <FaStar
              color={theme.colors.main.primary}
              size={theme.fontSizes.medium}
            />
          }
        />
        <Attend>{attend}</Attend>
        <Review>{review}</Review>
      </ReviewBody>
    </Wrapper>
  );
};

export default CustomerReview;
